export const typeBanner = 'HeaderBanner'
export const typeCategorias = 'CategoriasHome'
export const typeDestacadas = 'SeccionesDestacadas'
export const typeVideoHome = 'VideoHome'
export const typeInstagram = 'InstagramUser'
export const typeObtenerCatalogo = 'ObtenerCatalogo'
export const typeCatalogo = 'Catalogo'
export const typeReceta = 'Receta'
export const typeProducto = 'Producto'
export const typeConsejo = 'Consejo'
export const typeTiendas = 'Tiendas'
